/**
 * Music listing page.
 */

import React from "react"
import {Link, graphql} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Sidebar from "../components/sidebar";
import {GatsbyImage} from "gatsby-plugin-image";

const MusicIndex = ({data, location}) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`;
    const posts = data.allMarkdownRemark.nodes;

    if (posts.length === 0) {
        return (
            <Layout location={location} title={siteTitle}>
                <Seo title="All posts"/>
                <p>
                    No music posts found.
                </p>
            </Layout>
        )
    }

    return (
        <Layout location={location} title={siteTitle}>
            <Seo title="Music"/>
            <Sidebar quote={data.quote}/>

            <ol className="post-grid">
                {posts.map(post => {
                    const title = post.frontmatter.title || post.frontmatter.slug;
                    let featuredImg = post.frontmatter.featuredImage.childImageSharp.gatsbyImageData;
                    return (
                        <li key={post.frontmatter.slug}>
                            <article
                                className="post-list-item content"
                                itemScope
                                itemType="http://schema.org/Article"
                            >
                                <header>
                                    <Link to={post.frontmatter.slug} itemProp="url">
                                        <GatsbyImage image={featuredImg} alt={post.title}/>
                                        <h2>
                                            <span itemProp="headline">{title}</span>
                                        </h2>
                                    </Link>
                                </header>
                                <section>
                                </section>
                            </article>
                        </li>
                    )
                })}
            </ol>
        </Layout>
    )
};

export default MusicIndex

export const pageQuery = graphql`
  query(
    $quoteId: String
    ) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {contentType: {eq: "music"}}}) {
      nodes {
        excerpt
        frontmatter {
          slug
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 200
                  height: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
        }
      }
    }
    quote: markdownRemark(id: { eq: $quoteId }) {
      id
      html
      frontmatter {
        title
        author
        source
        source_link
      }
    }
  }
`;
